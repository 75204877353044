var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "900px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.SETT010T010.066"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _c("colgroup"),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.067")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.068")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.069")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.070")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.071")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.072")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.073")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.074")))]),
              ]),
            ]),
            _c(
              "tbody",
              [
                _vm._l(_vm.payList, function (item, idx) {
                  return [
                    _c("tr", { key: "pay_" + idx }, [
                      _c("td", [_vm._v(_vm._s(item.acNo))]),
                      _c("td", [_vm._v(_vm._s(item.blNo))]),
                      _c("td", [_vm._v(_vm._s(item.curCd))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(item.vrBankNm) + " " + _vm._s(item.vrAcctNo)
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.rcvDutDtm))]),
                      _c("td", [_vm._v(_vm._s(item.procCpltYn))]),
                      _c("td", [_vm._v(_vm._s(item.fstEntDtm))]),
                      _c("td", { staticClass: "text_right" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$ekmtcCommon.changeNumberFormat(item.amt, {
                                isComma: true,
                              })
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.075")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }