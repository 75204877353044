<template>
  <div class="popup_wrap" style="width:900px">
    <!-- popup_wrap : style="width:280px height:550px" 참고용, 개발시 제거 -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">{{ $t('msg.SETT010T010.066') }}</h1>
      <div class="content_box">
        <!--content_box  -->

        <table class="tbl_col">
          <colgroup>
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.SETT010T010.067') }}</th>
              <th>{{ $t('msg.SETT010T010.068') }}</th>
              <th>{{ $t('msg.SETT010T010.069') }}</th>
              <th>{{ $t('msg.SETT010T010.070') }}</th>
              <th>{{ $t('msg.SETT010T010.071') }}</th>
              <th>{{ $t('msg.SETT010T010.072') }}</th>
              <th>{{ $t('msg.SETT010T010.073') }}</th>
              <th>{{ $t('msg.SETT010T010.074') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, idx) in payList">
              <tr :key="'pay_' + idx">
                <td>{{ item.acNo }}</td>
                <td>{{ item.blNo }}</td>
                <td>{{ item.curCd }}</td>
                <td>{{ item.vrBankNm }} {{ item.vrAcctNo }}</td>
                <td>{{ item.rcvDutDtm }}</td>
                <td>{{ item.procCpltYn }}</td>
                <td>{{ item.fstEntDtm }}</td>
                <td class="text_right">
                  {{ $ekmtcCommon.changeNumberFormat(item.amt, { isComma: true }) }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div><!-- content_box // -->

      <div class="mt10 text_center">
        <a class="button blue lg" @click="$emit('close')">{{ $t('msg.SETT010T010.075') }}</a>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import CalcIssue from '@/api/rest/settle/calcIssue'

export default {
    name: 'CalcIssuePaymentListPop',
    components: {},
    props: {
      detail: {
        type: Object,
        default: () => {
          return {
            acNo: '',
            blNo: ''
          }
        }
      },
      isShowPop: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        idx: 0,
        total: 0,
        params: {
          blNo: '',
          acNo: 'KMT00121110001'
        },
        invoiceInfo: {},
        payList: []
      }
    },
    watch: {
      detail: {
        deep: true,
        handler (n, o) {
          this.showData()
        }
      },
      isShowPop (n, o) {
        this.showData()
      }
    },
    created () {},
    mounted () {
      this.showData()
    },
    methods: {
      showData () {
        if (this.isShowPop) {
          this.params.blNo = this.detail.blNo
          this.params.acNo = this.detail.acNo
          this.selectPayment(this.params)
        }
      },
      selectPayment (param) {
        CalcIssue.getPaymentList2(param)
          .then(response => {
            const resp = response.data
            this.payList = resp
            console.log(resp)
          })
          .catch(err => {
            console.log(err)
          })
      },
      openAlert (msg) {
        let obj = {
          alertType: 'simple',
          onClose: this.closeFun,
          customCloseBtnClass: 'button blue lg simplert-test1',
          customCloseBtnText: this.$t('msg.CSBK100.137'),
          useIcon: false,
          customClass: 'simple_alert',
          message: msg,
          type: 'info'
        }
        this.$ekmtcCommon.alert(obj)
      },
      closeFun () {
        this.$Simplert.close()
      }
    }
}

</script>

<style scoped>
a.no-uline:hover{
  text-decoration:underline
}
</style>
